import {
    changeSelectedBadgeTemplate,
    SET_PLATFORM,
    SET_SELFCHECKIN,
    SET_SESSION,
    SET_SA_SETTINGS,
    SET_PRINTER_LIBRARY,
    SET_TOTAL_CHECKIN_COUNT,
    SET_ALERT_DATA,
} from "./actions";

import {matchPath} from "react-router";

const match = matchPath(window.location.pathname, {
    path: "/:id",
    exact: false,
    strict: false,
});

const initialState = {
    platform: match?.params?.id || null,
    isSelfCheckIn:
        (localStorage.getItem("selfCheckIn") && JSON.parse(localStorage.getItem("selfCheckIn"))) ||
        false,
    // isSelfCheckIn: false,
    session:
        (localStorage.getItem("session") && JSON.parse(localStorage.getItem("session"))) || null,
    selectedBadgeTemplate: null,
    saSettings: localStorage.getItem("saSettings")
        ? JSON.parse(localStorage.getItem("saSettings"))
        : null,
    alertData: [],
    printerLibrary: "qz",
    totalCheckIn: {totalItemschkQty: 0, TotalCount: 0, totalItemschkQtynew: 0},
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLATFORM:
            return {
                ...state,
                platform: action.platform,
            };
        case SET_SELFCHECKIN:
            return {
                ...state,
                isSelfCheckIn: action.payload,
            };
        case SET_SESSION:
            return {
                ...state,
                session: action.payload,
            };
        case changeSelectedBadgeTemplate.type:
            return {
                ...state,
                selectedBadgeTemplate: action.payload,
            };
        case SET_SA_SETTINGS:
            localStorage.setItem("saSettings", JSON.stringify(action.payload));
            return {
                ...state,
                saSettings: action.payload,
            };
        case SET_PRINTER_LIBRARY:
            return {
                ...state,
                printerLibrary: action.payload,
            };
        case SET_ALERT_DATA:
            return {
                ...state,
                alertData: action.payload,
            };
        case SET_TOTAL_CHECKIN_COUNT:
            if (state?.session?.Id == action.payload.sessionId) {
                return {
                    ...state,
                    totalCheckIn: {
                        ...state.totalCheckIn,
                        ...action.payload,
                    },
                };
            }
            return state;
        default:
            return state;
    }
};

export default sidebarReducer;
