// project imports
import {createReducerWithEntities} from "@eventdex/api/src/store/entityFramework";
import {entities} from "./entities/entities";

const initialState = {};

const reducerKeys = {};

const reducer = createReducerWithEntities(entities, reducerKeys, initialState);

export default reducer;
