// reducer import
import customizationReducer from "./customizationReducer";
import snackbarReducer from "./snackbarReducer";
import cartReducer from "./cartReducer";
import kanbanReducer from "./kanbanReducer";
import sidebarReducer from "./sidebarReducer";
import entityReducer from "./entitesReducer";
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = {
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: cartReducer,
    // persistReducer(
    //     {
    //         key: 'cart',
    //         storage,
    //         keyPrefix: 'berry-'
    //     },
    //     cartReducer
    // ),
    kanban: kanbanReducer,
    sidebar: sidebarReducer,
    entities: entityReducer,
};

export default reducer;
