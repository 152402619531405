// import {ApiResource} from "@eventdex/api/src/store/entityFramework/types";
import EntityApi from "@eventdex/api/src/store/entityFramework/EntityApi";

const filters = [];

const Sponsors = {
    key: "Sponsors1",
    storeSlice: "ed_lounge",
    // fetch: getSponsors,
    // listNormalizer: normalizeSponsors,
    // entityNormalizer: normalizeSponsor,
    paginated: false,
};

export const sponsorsApi1 = new EntityApi(Sponsors);

export const entities = [sponsorsApi1];
