import React, {Suspense} from "react";
import ReactDOM from "react-dom";

// third party
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";

// import { PersistGate } from 'redux-persist/integration/react';
import {Loader} from "@eventdex/common/src/components/beatloader";
import "react-toastify/dist/ReactToastify.css";

// load mock apis
import "./_mockApis";

// project imports
// import * as serviceWorker from "./serviceWorker";
import {store} from "./store";
import {AdapterMoment as DateAdapter} from "@mui/x-date-pickers/AdapterMoment";
import {APP_SA_DEV_URL, APP_SA_URL} from "@eventdex/common/src/Consts";

// style + assets
import "./assets/scss/style.scss";
// @ts-ignore
import history from "@eventdex/bootstrap/src/hostConfig/history";
// @ts-ignore
import {initializeContext} from "@eventdex/core/src/hostApp";
import {LocalizationProvider} from "@mui/x-date-pickers";

import IframeChild from "@eventdex/event/src/services/iframe/iframeChild";
import LazyComponent from "@eventdex/common/src/helpers/LazyComponent";
import AiAssistant from "@eventdex/common/src/components/AiAssistant";
import * as process from "process";

let iframeChild = new IframeChild();
iframeChild.init();
// ==============================|| REACT DOM RENDER  ||============================== //

// This is a workaround. Please don't remove.
// @ts-ignore
window.React = React;
console.log("window.React:", window.React);

const AppLazy = React.lazy(() => import("./App"));

const WidgetRouterLazy = LazyComponent(() => import("routes/widgets/WidgetRouter"));
const App = (props: any) => (
    <Suspense
        fallback={
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    translate: "transform(-50%,-50%)",
                }}
            >
                <Loader loading={true}/>
            </div>
        }
    >
        <AppLazy {...props} />
        {process.env.REACT_APP_ASSISTANT_ID && <AiAssistant/>}
    </Suspense>
);

const isSaApp = window.location.origin === APP_SA_DEV_URL || window.location.origin === APP_SA_URL;

let hostApp = {
    clearAuthData: () => {
        console.log("Clearing local storage!!!");
        localStorage.clear();
    },
    abbreviation: "ep",
    name: "Eventdex Platform",
    accessPermissions: isSaApp
        ? {
            hasAccess: (user: any) => user?.role?.includes("Scan Attendee"),
            errorMessage: "Please purchase a ScanAttendee License to access the Badge Designer",
            redirectUrl: "/events",
            logoutOnNoAccess: false,
        }
        : null,
};
initializeContext(store, history, hostApp);

ReactDOM.render(
    <LocalizationProvider dateAdapter={DateAdapter}>
        <Provider store={store}>
            {/*<PersistGate loading={null} persistor={persister}>*/}
            {/* <ToastContainer
                style={{
                    position: "absolute",
                    zIndex: 10000,
                    width: "fit-content",
                    minWidth: 320,
                }}
                autoClose={30000}
            /> */}
            <BrowserRouter>
                <Switch>
                    <Route path="/iframe" component={null}/>
                    <Route
                        path={"/widgets/:publicOrJwt/:widgetname/:eventId/:token?"}
                        component={WidgetRouterLazy}
                        //if publicOrJwt is jwt  and token is not present then redirect Callback
                        // render={(props) => {
                        //     if (props.match.params.publicOrJwt === "jwt" && props.match.params.token) {
                        //         return <Callback {...props}  />;
                        //     }
                        //     return <WidgetRouterLazy {...props} />;
                        // }}
                    />
                    <Route path={"/"} component={App}/>
                </Switch>
            </BrowserRouter>
            {/*</PersistGate>*/}
        </Provider>
    </LocalizationProvider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
// swDev();
