// import { persistStore } from 'redux-persist';
import reducer from "./reducer";
import { configureAppStore } from "@eventdex/bootstrap/src/store/storeSetup";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureAppStore({}, reducer);

// const persister = persistStore(store);
const persister = {};
window.store = store;
export { store, persister };
